@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Bold.eot');
    src: local('GT Eesti Pro Display Bold'), local('GTEestiProDisplay-Bold'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Bold.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Bold.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-MediumItalic.eot');
    src: local('GT Eesti Pro Display Medium Italic'), local('GTEestiProDisplay-MediumItalic'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-MediumItalic.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Thin.eot');
    src: local('GT Eesti Pro Display Thin'), local('GTEestiProDisplay-Thin'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Thin.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Thin.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Light.eot');
    src: local('GT Eesti Pro Display Light'), local('GTEestiProDisplay-Light'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Light.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Light.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBold.eot');
    src: local('GT Eesti Pro Display UltraBold'), local('GTEestiProDisplay-UltraBold'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBold.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBold.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBoldItalic.eot');
    src: local('GT Eesti Pro Display UltraBold Italic'), local('GTEestiProDisplay-UltraBoldItalic'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBoldItalic.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Medium.eot');
    src: local('GT Eesti Pro Display Medium'), local('GTEestiProDisplay-Medium'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Medium.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Medium.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLight.eot');
    src: local('GT Eesti Pro Display UltraLight'), local('GTEestiProDisplay-UltraLight'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLight.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLight.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-RegularItalic.eot');
    src: local('GT Eesti Pro Display Regular Italic'), local('GTEestiProDisplay-RegularItalic'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-RegularItalic.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-RegularItalic.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-LightItalic.eot');
    src: local('GT Eesti Pro Display Light Italic'), local('GTEestiProDisplay-LightItalic'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-LightItalic.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLightItalic.eot');
    src: local('GT Eesti Pro Display UltraLight Italic'), local('GTEestiProDisplay-UltraLightItalic'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLightItalic.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLightItalic.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-BoldItalic.eot');
    src: local('GT Eesti Pro Display Bold Italic'), local('GTEestiProDisplay-BoldItalic'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-BoldItalic.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Regular.eot');
    src: local('GT Eesti Pro Display Regular'), local('GTEestiProDisplay-Regular'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Regular.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Regular.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GT Eesti Pro Display';
    src: url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-ThinItalic.eot');
    src: local('GT Eesti Pro Display Thin Italic'), local('GTEestiProDisplay-ThinItalic'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-ThinItalic.woff2') format('woff2'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-ThinItalic.woff') format('woff'),
        url('/assets/fonts/gtrestiprodisplay/GTEestiProDisplay-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

